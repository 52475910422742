import React from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../layouts/HomeLayout"
import IndexLayout from "../layouts/IndexLayout"
import { Grid, Paper, Avatar } from "@material-ui/core"
import AppFooter from "../components/Appfooter"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import './whatsapp.css'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const building = require("../../assets/landing/background.jpeg")

export default function Home() {
  return (
    <div>
      <IndexLayout>
        <img src={building} height={540} width={"105%"} />
        <div>
          <Grid
            spacing={0}
            container
            justify="center"
            style={{
              backgroundColor: "#dadee3",
              paddingBottom: 50,
              paddingTop: 0,
            }}
          >
            <div style={{ width: "80%" }}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: 30,
                  fontSize: 28,
                  color: "#ba425e",
                }}
              >
                About
              </p>

              <p>
                HARRISON ASSET MANAGEMENT is an advisory firm of NSE, BSE,
                MCX-SX, MCX, NCDEX and a depository participant with CDSL. Since
                beginning working towards being a trusted advisor and wealth
                creator for its clients and not just a company. From asset
                allocation support, trading of securities to specialized
                investment vehicles.
                <br />
                <br /> We extensively focuses on research and technology as core
                areas to shape its growth and improving client satisfaction. It
                has built a robust infrastructure to meet growing requirements
                of its clients and to over time bound delivery of quality
                services.
                <br />
                <br /> We are entrepreneurs; all inspired by a vision of a world
                where we create a one stop financial hub & we’re a door you
                should knock when you think of Finance! With a robust presence
                across Equity & commodity broking, Mutual Fund Distribution,
                Insurance Broking, Management Consulting, Investment Banking,
                Infrastructure Consulting.
              </p>

              <p
                style={{
                  textAlign: "left",
                  marginTop: 30,
                  fontSize: 28,
                  color: "#ba425e",
                }}
              >
                Portfolio Management Services
              </p>

              <p>
                At HARRISON's, our Portfolio Management Services are highly
                customized to cater to your needs, and enable you to achieve
                your financial goals. <br /> <br /> Our prime focus is to create
                wealth for the clients & our expertise in the areas of extensive
                research, highly qualified investment team and a rich experience
                of over a decade in the financial services space, enables us to
                achieve this goal for you. <br /> <br /> We manage existing
                portfolios as well as build new distinct portfolios for you
                based on your investment objectives and risk appetite, for which
                we oﬀer a wide range of strategies including some focused
                thematic strategies, which aim at maximizing the investors
                wealth while making investments in diversiﬁed sectors which have
                a potential to grow at a faster pace, with a sustainable
                management & corporate governance standards.
              </p>
            </div>
          </Grid>
          <Grid
            spacing={2}
            container
            alignItems="center"
            style={{
              backgroundColor: "#4c4e73",
            }}
          >
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  01
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Experienced & Professional Fund Management
                </p>
              </div>
            </Grid>
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  02
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  High level of Transparency
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  03
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Finely Crafted Investment Philosophy
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  04
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Exhaustive Research Desk
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  05
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Timely review of Fund performance
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  06
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Devoted Relationship with Client Desk
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={4} lg={4} align="center">
              <div>
                <p style={{ color: "#FFF", fontSize: 100, fontWeight: "bold" }}>
                  07
                </p>
                <p style={{ color: "#FFF", width: 200 }}>
                  Experienced & High level of Finely Crafted Professional
                  Transparency Investment Fund Management
                </p>
              </div>
            </Grid>{" "}
            <Grid item xs={12} xl={8} lg={8} align="center">
              <div
                style={{
                  backgroundColor: "#ba425e",
                  margin: 50,
                  marginRight: 0,
                }}
              >
                <p style={{ color: "#FFF", fontSize: 40, fontWeight: "bold" }}>
                  HARRISON ASSET MANAGEMENT OFFERS YOU
                </p>
              </div>
            </Grid>{" "}
          </Grid>
          <Grid spacing={2} container alignItems="center">
            <Grid item xs={12} lg={4} style={{ width: "100%", padding: 30 }}>
              <img
                width="400px"
                src={require("../../assets/landing/profile.png")}
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={8} style={{}}>
              <div
                style={{
                  backgroundColor: "#ba425e",
                  padding: 40,
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  OUR FUND MANAGER
                </p>
                <p style={{ color: "#FFF" }}>
                  Mr. Mosses Harrison B.E (Chem Eng) With the fund managers with
                  a decade of experience in the capital markets, they posses a
                  name of repute & the results our investors can truly bank
                  upon! <br />
                  <br />
                  Mr. Mosses Harrison with more than 10years of experience in the
                  ﬁeld of Financial Industry and Consultancy, he has an
                  expertise in several areas like Investment Banking, Financial
                  Consultancy regarding Merger and Acquisitions. Apart from the
                  above mentioned accomplishments he also has articulacy in the
                  ﬁeld of Wealth Creation and Business Valuation.
                  <br /> <br /> Currently he is heading the Equity Fundamental
                  Research team at HARRISON ASSET MANAGEMENT for the last 6
                  years with a team of over 5 research analysts responsible for
                  research in various sectors such as BFSI, Power & Steel, IT,
                  Pharmaceutical, logistics, Infrastructure etc. The team has
                  provided excellent reports on many multibagger stocks and
                  undertaken extensive research in many industries under his
                  supervision and guidance. Fundamental team at choice has
                  issued 2-3 times “dynamic portfolio” (10 selected stock for
                  investment at a particular time for diﬀerent time frames) for
                  its clients, which has outperformed and given 30-40 % returns
                  in a suggested period.
                  <br />
                  <br />
                  Previously, he served as an Independent Financial Consultant
                  for a period of over 8 years, ﬁnally moving on to become
                  founder of HARRISON ASSET MANAGEMENT in the year 2018, a
                  renowned investment Firm, based in Doha, Qatar now having pan
                  India presence.
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid
            spacing={0}
            container
            justify="center"
            style={{
              backgroundColor: "#ba425e",
              paddingBottom: 50,
              paddingTop: 50,
            }}
          >
            <Grid item xs={12} lg={12}>
              {" "}
              <p
                style={{
                  color: "#FFF",
                  fontSize: 30,
                  fontWeight: "bold",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                ONE STOP FINANCIAL HUB FOR ALL YOUR INVESTMENT NEEDS
              </p>
            </Grid>

            <Grid item xs={12} lg xl justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px 3px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                EQUITY TRADING
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px 3px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                CURRENCY DERIVATIVE TRADING
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px 3px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                COMMODITY DERIVATIVE TRADING
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px 3px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                PUBLIC OFFER
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px 3px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                FINANCIAL PLANNING
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px31px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                STRUCTURED PROJECT
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px31px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                PORTFOLIO MANAGEMENT SERVICE
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px31px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                MUTUAL FUNDS
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px31px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                INSURANCE PRODUCTS
              </p>
            </Grid>
            <Grid item xs={12} lg={3} xl={3} justify="center" align="center">
              <Avatar
                style={{
                  backgroundColor: "#FFF",
                  width: 100,
                  height: 100,
                  margin: 20,
                  boxShadow: "3px 3px31px #9E9E9E",
                }}
              >
                <TrendingUpIcon style={{ color: "#ba425e", fontSize: 80 }} />
              </Avatar>
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                BONDS AND DEBUNTURES
              </p>
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            container
            alignItems="center"
            style={{
              backgroundImage: `url(${require("../../assets/landing/background2.jpeg")})` /* The image used */,
              backgroundColor: "#cccccc" /* Used if the image is unavailable */,
              backgroundPosition: "center" /* Center the image */,
              backgroundRepeat: "no-repeat" /* Do not repeat the image */,
              backgroundSize:
                "cover" /* Resize the background image to cover the entire container */,
            }}
          >
            <Grid item xs={12} lg={4} style={{ width: "100%", padding: 30 }}>
              {/* <img
                width="76%"
                src={require("../../assets/landing/india.jpg")}
              /> */}
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              xl={8}
              style={{
                padding: 30,
              }}
            >
              <div
                style={{
                  backgroundColor: "#4c4e73",
                  padding: 30,
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  NEW INDIA STRATEGY (COVID-19)
                </p>
                <p style={{ color: "#FFF" }}>
                  India has become a favourable investment destination in the
                  world on the back of following factors: 4Progress on policy
                  front improving business environment 4Stable macro-economic
                  fundamentals. 4Huge market size & demographic dividend 4Low
                  political uncertainties and less sensitivity towards the
                  global market.
                  <br />
                  <br />
                  <ol style={{}}>
                    <li>
                      A distinct stamp is being placed on India as structural
                      change has occurred across various dimensions.
                      Implementation of favourable initiatives such as GST, IBC,
                      RERA, DBT and demonetization has made structural changes
                      in economy which would not only yield result in the form
                      of high quality economic growth but generate adequate
                      employment in the long term.
                    </li>
                    <br />
                    <li>
                      Economic reforms have given impetus to India’s new mantra
                      for success ‘IRADE’ (Infrastructure, Rural Consumption/
                      Renewable Energy, Aﬀordable Housing, Digitalization and
                      Emerging Businesses); will ﬂourish more industrial avenues
                      making “New India” unrivalled. This HTML is created from
                      PDF at https://www.pdfonline.com/convert-pdf-to-html/
                    </li>
                  </ol>
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid spacing={2} container alignItems="center">
            <Grid item xs={12} lg={8} xl={8} style={{}}>
              <div
                style={{
                  backgroundColor: "#ba425e",
                  padding: 30,
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  IDEAL VALUATION STRATEGY (POST-LOCKDOWN)
                </p>
                <p style={{ color: "#FFF" }}>
                  Derived by: Mr. Mosses Harrison <br />
                  <br />
                  PRESENTING THE ‘IDEAL VALUATION STRATEGY’ <br />
                  <br /> The Ideal Valuation Strategy aims to create wealth by
                  the long term compounding eﬀect on investments done in:-{" "}
                  <br />
                  <li>
                    Good Business <br />
                  </li>
                  <li>
                    Good Management <br />
                  </li>
                  <li>
                    Good Valuations <br />
                  </li>
                  <br />
                  We seek to capitalize the opportunities that come up in the
                  emotionally driven markets, which lead some very fundamentally
                  strong companies to trade at a considerable discount than its
                  intrinsic value.
                  <br /> <br />
                  or simply the stocks at a price much lower Our strategy is to
                  buy the undervalued stocks, than its fair price, irrespective
                  of the market movements.
                  <br />
                  <br />
                  The big market whales and the mammoths of value investing,
                  including Warren Buﬀet, have argued that the essence of value
                  investing is buying stocks below its intrinsic value.
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              style={{ width: "100%", height: "80vh", padding: 30 }}
            >
              <img
                width="110%"
                height="100%"
                src={require("../../assets/landing/bulb.png")}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={12} xl={12} justify="center" align="center">
            <p
              style={{
                fontSize: 35,
                fontWeight: "bold",
                marginTop: 30,
                color: "#4c4e73",
              }}
            >
              Our Investment Plans
            </p>
            <img
              width={"60%"}
              style={{ marginTop: 30 }}
              src={require("../../assets/Logo/logo.png")}
            ></img>

            <img
              width={"43%"}
              src={require("../../assets/landing/contact.png")}
            ></img>
          </Grid> */}
          <AppFooter/>
          <a
        href="https://wa.me/+917708106595?text=Welcome"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon style={{fontSize:"30px"}}/>
      </a>
        </div>
      </IndexLayout>
    </div>
  )
}
