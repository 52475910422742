import React from "react"
import YouTubeIcon from "@material-ui/icons/YouTube"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"

const Footer = () => {
  return (
    <div
      style={{
        height: "6vh",
        backgroundColor: "#E51C21",
        marginBottom: 0,
        padding: 10,
        paddingBottom: 15,
        marginTop: "200vh",
        textAlign: "center",
      }}
    >
      <InstagramIcon style={{ marginLeft: 30, color: "#fff" }} />
      <FacebookIcon style={{ marginLeft: 30, color: "#fff" }} />
      <YouTubeIcon style={{ marginLeft: 30, color: "#fff" }} />
    </div>
  )
}

export default Footer
