import React from "react"
import Header from "../components/Header"
import IndexFooter from "../components/IndexFooter"
import IndexHeader from "../components/IndexHeader"

const HomeLayout = ({ children }) => {
  return (
    <React.Fragment>
      <IndexHeader />
      <div
        style={{
          position: "absolute",
          overflowY: "hidden",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        {children}
      </div>
      <IndexFooter />
    </React.Fragment>
  )
}

export default HomeLayout
