import React from "react"
import { Home } from "@material-ui/icons"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    height: "14vh",
    [theme.breakpoints.up("xs")]: { height: "17vh" },
    padding: 10,
  },
}))

const Header = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={require("../../assets/Logo/logo.png")} width={180} />

      <div style={{ float: "right", padding: 20, display: "inline" }}>
        <Link to="/instagram">
          {" "}
          <button
            type="button"
            className="btn btn-outline-danger"
            style={{ marginLeft: 10 }}
          >
            Instagram feed
          </button>
        </Link>
        <Link to="/login">
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginLeft: 10, backgroundColor: "#E51C21" }}
          >
            Login
          </button>
        </Link>
      </div>
      <div
        style={{ float: "right", padding: 20, marginTop: 6, display: "inline" }}
      >
        <Link to="/home">
          <Home style={{ marginLeft: 10, color: "#6AA5E3" }} />
        </Link>
      </div>
    </div>
  )
}

export default Header
